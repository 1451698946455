.footer {
  height: 500px;
  background: linear-gradient(45deg, #FFAA33 0%, #DD1188 100%);
  color: whitesmoke;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div:first-child {
    font-size: 32px;
    margin-bottom: 32px;
  }

  > div:nth-child(2) {
    padding-bottom: 32px;
  }

  > div:nth-child(3) {
    > *:first-child {
      margin-right: 16px;
    }
  }
}

@media (max-width: 450px) {
  .footer {
    > div:first-child {
      font-size: 28px;
    }
  }
}
