.navbar {
  height: 82px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  //box-shadow:  0 0 8px 8px rgba(0,0,0,7%);
  //box-shadow:  0 0 6px 6px #FFAA3344;
  box-shadow:  0 0 12px 6px #FFAA3388;
  margin-bottom: 14px;
  z-index: 99999;
  color: #666;
  font-weight: bold;
  font-size: 24px;

  div {
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;

    > *:not(:last-child) {
      margin-right: 32px;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
  padding: 12px;
  transition: ease-out 0.4s;
  border: 8px solid transparent;

  &:hover {
    border: 8px solid;
    border-radius: 40px;
  }

}


.orange {
  &.active {
    border-color: #FFAA3388;
    border-radius: 40px;
  }
  &:hover {
    border-color: #FFAA33
  }
}

.purple {
  &.active {
    border-color: #EF6B5588;
    border-radius: 40px;
  }
  &:hover {
    border-color: #EF6B55
  }
}

.pink {
  &.active {
    border-color: #e4397188;
    border-radius: 40px;
  }
  &:hover {
    border-color: #e43971;
  }
}

.language-switcher {
  position: absolute;
  right: 12px;
  cursor: pointer;
  z-index: 99999;
}

@media (max-width: 800px) {
  .navbar {

    div {
      font-size: 18px;
    }

    div > * {
      flex: 1 1 0;
    }
    div > *:not(:last-child) {
      margin-right: 0;
    }

    a {
      text-align: center;
    }
  }

  a {
    padding: 4px;
  }

  .language-switcher {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background: white;
    border-radius: 32px;
    box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
  }
}
